// APP

@mixin w-h-status-overview-search-util-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);
  .w-h-status-overview-search {
    padding-top: 6px;
    display: flex;
    align-items: center;
    gap: 26px;
    width: 100%;

    .mat-chip-list-wrapper input.mat-input-element {
      min-height: 29px;
    }
    .mat-mdc-form-field-flex {
      padding-left: 16px;
    }
    .mat-option-text {
      padding-left: 24px;
    }
  
    mat-form-field { 
      width: calc(calc(100% / (var(--numOfColumns) - 1)) - 28px)
    }

    .chip-body-span {
      min-width: 128px;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .workheld-icons.result-icon {
    vertical-align: middle;
    color: mat.get-color-from-palette($primary);
  }

  .mdc-list-item__primary-text:has(mat-spinner) {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

mat-chip {
  max-width: 400px;
}