@mixin mat-dialog-add-shift-definition-component-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);

  .label-wrapper {
    margin-bottom: 16px;
  }

  .workspace-icon {
    color: #fff;
  }

  .mat-dialog-add-shift-definition-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 100%;

    p {
      color: #999999;
      font-size: 16px;
    }

    .title {
      color: mat.get-color-from-palette($primary, 500);
      font-weight: 600;
    }

    .max-height {
      height: 100%;
    }

    .autocomplete-container {
      width: 100%;
    }

    .confirm-label {
      align-self: center;
      color: mat.get-color-from-palette($primary);
    }

    .mat-dialog-add-content {
      padding: 16px;

      .shift-definitions-table {
        max-height: 50vh;
        overflow: scroll;

        .disabled-row {
          display: none !important;
          background-color: #cbcbcb;
          &:hover {
            background-color: #cbcbcb !important;
          }
        }
      }
    }

    .toggle-renewed {
      display: flex;
      flex-direction: column;
      padding-bottom: 16px;

      .title {
        font-weight: bold;
        color: mat.get-color-from-palette($primary);
      }
    }
  }
}
