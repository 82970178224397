// APP

@mixin app-legacy-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);

  @include xs {
    .mat-mdc-card-avatar.small-avatar {
      width: 16px;
      height: 16px;
      border: 2px solid mat.get-color-from-palette($accent);
      margin-left: 2px;
    }

    .mat-stroked-button.create {
      padding: 0;
      min-width: 28px;
      min-height: 28px;
      border-radius: 25px;
      line-height: inherit;
      margin-left: 8px;

      i.workheld-icons {
        font-size: 20px;
      }
    }

    .workers-leftover-size {
      width: 16px;
      margin-left: unset;
      color: #63a021;
      border-color: var(--accent-color-500);
      border-width: 2px;
      border-style: solid;
      border-radius: 50%;

      div {
        margin: 0px -5px;
        text-align: center;
      }
    }

    .assignment-count {
      min-width: 24px;
      min-height: 24px;
      border-radius: 50%;
      text-align: center;
      line-height: 1.7;
      font-size: 15px;
      display: inline-block;
      margin: 0 2px;
    }
  }

  @include sm {
    .mat-mdc-card-avatar.small-avatar {
      width: 16px;
      height: 16px;
      border: 2px solid mat.get-color-from-palette($accent);
      margin-left: 2px;
    }

    .workers-leftover-size {
      width: 16px;
      margin-left: unset;
      color: #63a021;
      border-color: var(--accent-color-500);
      border-width: 2px;
      border-style: solid;
      border-radius: 50%;

      div {
        margin: 0px -5px;
        text-align: center;
      }
    }
  }

  @include md {
    .mat-mdc-card-avatar.small-avatar {
      width: 20px;
      height: 20px;
      border: 2px solid mat.get-color-from-palette($accent);
      margin-left: 2px;
    }

    .workers-leftover-size {
      width: 20px;
      height: 20px;
      margin-left: 2px;
      color: #63a021;
      border-color: var(--accent-color-500);
      border-width: 2px;
      border-style: solid;
      border-radius: 50%;
    }
  }

  @include lg {
    .mat-mdc-card-avatar.small-avatar {
      width: 24px;
      height: 24px;
      border: 2px solid mat.get-color-from-palette($accent);
      margin-left: 2px;
    }

    .workers-leftover-size {
      width: 24px;
      height: 24px;
      margin-left: 2px;
      color: #63a021;
      border-color: var(--accent-color-500);
      border-width: 2px;
      border-style: solid;
      border-radius: 50%;

      div {
        margin: 2px -5px;
        text-align: center;
      }
    }

    .mat-stroked-button.create {
      border-radius: 25px;
      padding: 0;
      min-width: 40px;
      min-height: 40px;
      margin-left: 14.5;

      i.workheld-icons {
        font-size: 24px;
      }
    }
  }

  @include xl {
    .workers-leftover-size {
      width: 24px;
      margin-left: 2px;
      color: #63a021;
      border-color: var(--accent-color-500);
      border-width: 2px;
      border-style: solid;
      border-radius: 50%;

      div {
        margin: 2px -5px;
        text-align: center;
      }
    }

    .mat-mdc-card-avatar.small-avatar {
      width: 24px;
      height: 24px;
      border: 2px solid mat.get-color-from-palette($accent);
      margin-left: 2px;
    }
  }

  .route {
    position: relative;
    margin-top: $padding__page;

    .route-container {
      // position: relative;
      .route-body {
        position: relative;
        padding: 0 $padding__page $padding__page $padding__page;
        overflow: auto;
        // overflow-x: hidden;
        max-height: calc(100vh - 112px);
        min-height: calc(100vh - 112px);
      }
    }

    .case-container {
      // position: relative;
      .route-body {
        position: relative;
        padding: 0;
        overflow: auto;
        overflow-x: hidden;
        max-height: calc(100vh - 112px);
        min-height: calc(100vh - 112px);
      }
    }
  }

  .scheduler-route {
    position: relative;
    margin-top: 0px;

    .route-container {
      // position: relative;
      .route-body {
        position: relative;
        padding: 0;
      }
    }
  }

  .assignment-span {
    background-color: #fff;
    margin-top: 16px;
    margin-right: 16px;
    height: calc(100vh - 135px);

    .mat-tab-body {
      overflow: hidden;
      height: calc(100vh - 162px);
      overflow-x: hidden;

      .mat-tab-body-content {
        height: 100%;
        overflow: hidden;
        // padding: 16px 24px 16px 16px;
      }
    }

    .mat-tab-label {
      height: 48px;
      padding: 0px;
      cursor: pointer;
      box-sizing: border-box;
      opacity: 1;
      min-width: 0px;
      text-align: center;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      white-space: nowrap;
      position: relative;
      background-color: #fff;
      color: mat.get-color-from-palette($primary, 500);
    }

    .mat-tab-label-active {
      // background-color: mat.get-color-from-palette($accent, 500);
      color: #fff;
    }

    .mat-tab-label-active-selected {
      // background-color: mat.get-color-from-palette($accent, 500);
      color: #fff;
    }
  }

  .entry-left-menu-spacer {
    max-width: 64px;
    margin-top: 63px;
    height: 41px;
    background-color: $theme-app-bar;
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
  }

  .w-h-docu-span {
    max-width: calc(100% - 64px);
  }

  .nav-list-bar-span {
    max-width: 64px;
    background-color: $theme-app-bar;
  }

  .w-h-docu-span {
    width: calc(100% - 64px);
  }

  .top-ext-id {
    font-size: 12px;
  }

  .workheld-icons.accent {
    color: mat.get-color-from-palette($accent);
  }

  // TOP HEADER
  .work-object-create-edit-form-header {
    // padding: 16px 8px;
    background-color: mat.get-color-from-palette($primary, 500);

    .mat-mdc-card-avatar.workheld-icons.header-icon {
      border-radius: 0px;
      height: 48px;
      width: 64px;
      text-align: center;
      line-height: 1.5;
      font-size: 34px;
      background-color: mat.get-color-from-palette($primary, 500);
      color: #fff;
    }
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .state-text {
    height: 25px;
    width: 25px;
  }

  .state-dot {
    height: 5px;
    width: 5px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
  }

  .state-dot.green {
    min-height: 25px;
    min-width: 25px;
    background-color: $operatingStateId1;
  }

  .state-dot.orange {
    min-height: 25px;
    min-width: 25px;
    background-color: $operatingStateId2;
  }

  .state-dot.red {
    min-height: 25px;
    min-width: 25px;
    background-color: $operatingStateId3;
  }

  .state-dot.grey {
    min-height: 25px;
    min-width: 25px;
    background-color: $operatingStateId4;
  }

  .mat-stroked-button:not([disabled]) {
    border-color: mat.get-color-from-palette($accent);
  }

  .accent-icon {
    color: mat.get-color-from-palette($accent, 500);
  }

  .detail-card-header {
    background-color: mat.get-color-from-palette($primary, 500);
  }

  .mat-mdc-card-avatar.workheld-icons.header-icon {
    border-radius: 0px;
    height: 48px;
    width: 64px;
    text-align: center;
    line-height: 1.5;
    font-size: 34px;
    background-color: mat.get-color-from-palette($primary, 500);
    color: #fff;
  }

  .header-content-wrapper {
    display: inline-block;
    width: 100%;
    vertical-align: bottom;
    max-width: calc(100% - 64px);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    .header-ext-id {
      color: #fff;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .header-title {
      color: #fff;
      margin-bottom: 4px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .w-h-header-icon-span {
    max-width: 64px;
  }

  .w-h-header-span {
    max-width: calc(100% - 64px);
  }

  .default-max-width {
    max-width: 400px;
  }

  .single-header {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: #fff;
    font-size: 20px;
    line-height: 1.8;
  }

  .html-overflow {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 98%;
  }

  .default-value {
    min-height: 18px;
  }

  .paginator {
    display: block;
    background: $theme-app-bar;
  }

  .semi-bold {
    font-weight: 500;
  }

  .bold {
    font-weight: bold;
  }

  .material-detail-span {
    padding-left: $padding__page;
    max-width: calc(100% - 36px);
  }

  .material-icon-span {
    width: 36px;
    height: 36px;
    text-align: center;
    display: inline-block;

    .material-avatar {
      width: 36px;
      height: 36px;
    }

    .workheld-icons.material-icon {
      font-size: 36px;
      color: #63a021;
      color: mat.get-color-from-palette($accent);
    }
  }

  .avatar {
    width: 40px;
    height: 40px;
    // border-radius: 50%;
  }

  .workheld-icons.feature-icon {
    margin: auto;
    color: mat.get-color-from-palette($accent);
  }

  .mat-mdc-nav-list.entry-nav-list {
    padding: 0px;
    background-color: #fff;
    // height: calc(100vh - 544px);
    max-width: 64px;
  }

  .mat-mdc-list-item.active-entry {
    background-color: mat.get-color-from-palette($accent);
    color: mat.get-color-from-palette($accent, 50);

    .workheld-icons.feature-icon {
      color: mat.get-color-from-palette($accent, 50);
    }
  }

  .mat-mdc-list-item.active-entry:hover {
    background-color: mat.get-color-from-palette($accent);
    color: mat.get-color-from-palette($accent, 50);
  }

  // WORK OBJECT -------------------------------------------

  w-h-event-log-data-table.work-object-entry-data-table
    .w-h-event-log-data-table {
    .data-table-container {
      min-height: 282px;
    }

    .data-table {
      min-height: calc(100vh - 728px);
    }
  }

  ng-bee-work-step-master-data-table.work-object-work-step-master-data-table
    .work-step-master-data-table {
    .ng-bee-list {
      margin-bottom: 0px;
      min-height: unset;

      .data-table {
        min-height: 282px;
        height: calc(100vh - 650px);
      }
    }
  }

  ng-bee-work-order-master-data-table.work-object-work-order-master-data-table
    .work-order-master-data-table {
    .ng-bee-list {
      margin-bottom: 0px;
      min-height: unset;

      .data-table-container {
        min-height: 282px;

        .data-table {
          min-height: calc(100vh - 724px);
        }
      }
    }
  }

  // WORK OBJECT ENTRIES
  ng-bee-entry-data-table.work-object-entry-data-table {
    .entry-data-table-container .data-table-container {
      min-height: 282px;

      .data-table {
        min-height: calc(100vh - 728px);
      }
    }
  }

  ng-bee-checklist-instance-data-table.work-object-entry-data-table {
    .checklist-instance-data-table .data-table-container {
      min-height: 282px;

      .data-table {
        min-height: calc(100vh - 728px);
      }
    }
  }

  ng-bee-entry-file-data-table.work-object-entry-data-table
    .entry-file-data-table {
    .entry-file-data-table-container .data-table-container {
      min-height: 282px;

      .data-table {
        min-height: calc(100vh - 728px);
      }
    }
  }

  ng-bee-report-file-data-table.work-object-entry-data-table {
    .report-file-data-table-container .data-table-container {
      min-height: 282px;

      .data-table {
        min-height: calc(100vh - 728px);
      }
    }
  }

  // WORK ORDER --------------------------------------------
  ng-bee-entry-data-table.work-order-entry-data-table {
    .entry-data-table-container .data-table-container {
      min-height: 258px;

      .data-table {
        min-height: calc(100vh - 726px);
      }
    }
  }

  ng-bee-checklist-instance-data-table.work-order-entry-data-table {
    .checklist-instance-data-table .data-table-container {
      min-height: 258px;

      .data-table {
        min-height: calc(100vh - 726px);
      }
    }
  }

  ng-bee-entry-file-data-table.work-order-entry-data-table
    .entry-file-data-table {
    .entry-file-data-table-container .data-table-container {
      min-height: 258px;

      .data-table {
        min-height: calc(100vh - 726px);
      }
    }
  }

  ng-bee-report-file-data-table.work-order-entry-data-table {
    .report-file-data-table-container .data-table-container {
      min-height: 258px;

      .data-table {
        min-height: calc(100vh - 726px);
      }
    }
  }

  ng-bee-work-step-master-data-table.work-order-work-step-master-data-table
    .work-step-master-data-table {
    .ng-bee-list {
      margin-bottom: 0px;
      min-height: unset;
    }

    .work-step-master-data-table-container .data-table-container {
      min-height: 258px;

      .data-table {
        min-height: calc(100vh - 722px);
      }
    }
  }

  // -------------------------------------------------------

  .export-note {
    text-align: center;
    margin-bottom: $padding__page;
  }

  .w-h-default-button {
    background-color: #fff;
    color: mat.get-color-from-palette($accent, 500);
  }

  .small-btn {
    height: 30px !important;
  }

  .name-span {
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .w-h-default-button.default-button-right {
    margin-right: $padding__page;
  }

  .w-h-title {
    font-weight: bold;
  }

  .w-h-label {
    color: mat.get-color-from-palette($primary, 500);
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 15px;
    line-height: 15px;
  }

  .w-h-label.entry-detail-label {
    margin-bottom: 4px;
  }

  .w-h-label-no-margine {
    color: mat.get-color-from-palette($primary, 500);
    margin-bottom: 0px;
    font-weight: 600;
    font-size: 15px;
  }

  .row-wrapper {
    margin-bottom: $padding__page;
  }

  .row-wrapper.entry-detail-row-wrapper {
    margin-bottom: 16px;
  }

  .detail-wrapper {
    padding-top: 16px;
  }

  .detail-actions-wrapper {
    text-align: right;
    padding: 0 $padding__page $padding__page $padding__page;
    display: flex;
    .action-button {
      margin-left: $padding__page;
    }
  }

  .delete-button {
    margin-right: $padding__page;
  }

  .workheld-icons.chacklist-definition-avatar {
    font-size: 34px;
    width: 34px;
    height: 34px;
    color: mat.get-color-from-palette($accent, 500);
    cursor: pointer;
  }

  .workheld-icons.icon-disabled {
    color: #ddd;
  }

  .mat-mdc-cell.image-cell {
    color: mat.get-color-from-palette($primary, 500);

    .workheld-icons {
      font-size: 35px;
    }
  }

  .mat-mdc-cell.icon-cell {
    color: mat.get-color-from-palette($primary, 500);
  }

  .mat-mdc-cell.image-cell.with-img {
    color: mat.get-color-from-palette($primary, 500);

    .workheld-icons {
      font-size: 35px;
    }
  }

  .mat-mdc-row {
    outline: none;

    .workheld-icons.primary-icon {
      color: mat.get-color-from-palette($primary);
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .mat-mdc-cell.flex-unset {
    flex: unset;
  }

  .mat-icon.arrow_forward_ios {
    color: mat.get-color-from-palette($accent);

    &:hover {
      transform: scale(1.2);
    }
  }

  .mat-button-wrapper > * {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .mat-flat-button[disabled][disabled] {
    cursor: not-allowed;
  }

  .mat-stroked-button[disabled][disabled] {
    cursor: not-allowed;
  }

  .mat-stroked-button[disabled][disabled]:hover {
    color: rgba(0, 0, 0, 0.26);
  }

  .image-span {
    padding: 0 $padding__page;
  }

  .mat-tab-body-content {
    overflow: hidden;

    ng-bee-w-h-file-export-button-util:nth-child(1) {
      margin-top: 12px;
      display: block;
    }
  }

  .mat-tab-group.mat-primary .mat-ink-bar {
    background-color: mat.get-color-from-palette($accent);
  }

  .mat-tab-labels {
    .mat-tab-label {
      &.mat-tab-label-active {
        color: mat.get-color-from-palette($accent);
        opacity: 1;
      }
    }
  }

  .material-icons.primary {
    color: mat.get-color-from-palette($primary);
  }

  .material-icons.accent {
    color: mat.get-color-from-palette($accent);
  }

  .material-icons.warn {
    color: mat.get-color-from-palette($warn);
  }

  .material-icons.medium {
    transform: scale(1.2);
  }

  .material-icons.workheld {
    font-family: "workheld-icons", sans-serif;
  }

  .material-chip.primary {
    background-color: mat.get-color-from-palette($primary);
    color: #fff;
  }

  .material-chip.accent {
    background-color: mat.get-color-from-palette($accent);
    color: #fff;
  }

  .material-chip.warn {
    background-color: mat.get-color-from-palette($warn);
    color: #fff;
  }

  .material-icon-white {
    color: #fff;
  }
}

.truncate-text {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
  white-space: nowrap;
}

.mat-mdc-chip.mdc-evolution-chip--with-trailing-action
  .mat-mdc-chip-action-label {
  display: grid;
}

.export-buttons-grid {
  justify-content: center;
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(auto-fill, 350px);

  @media (max-width: 1850px) {
    grid-template-columns: repeat(auto-fill, 480px);
  }

  @media (max-width: 750px) {
    grid-template-columns: repeat(auto-fill, 300px);
  }
}
