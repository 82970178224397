@mixin mat-dialog-add-shift-group-component-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);

  .label-wrapper {
    margin-bottom: 16px;
  }

  .mat-dialog-add-shift-group-container {
    display: flex;
    flex-direction: column;
    width: 1200px; //width of the dialog when it is empty
    max-width: 100%;

    .mat-dialog-add-shift-content {
      flex-grow: 1;
      max-height: 95vh;
      overflow: scroll;

      p {
        color: #999999;
        font-size: 16px;
      }

      .align-text-center {
        text-align: center;
      }
    }
  }

  .confirm-label {
    align-self: center;
  }

  .shift-group-page-table {
    td,
    th {
      padding-right: 20px;
      text-align: left;
    }
  }

  .detail-page-table {
    td,
    th {
      padding-right: 20px;
      text-align: left;
    }
  }

  .title {
    color: mat.get-color-from-palette($primary, 500);
    font-weight: 600;
  }
}
