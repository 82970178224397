@use "@angular/material" as mat;
// 3LIB
@import "../node_modules/ngx-toastr/toastr";
@import "@angular/cdk/overlay-prebuilt.css";
// Plus imports for other components in your app.
// @import "@workheld/workheld-shared-lib/styles/fonts";
@import "../node_modules/@workheld/workheld-shared-lib/styles/fonts";
@import "../node_modules/@workheld/workheld-shared-lib/styles/workheld-angular-material.theme";

// BRYNTUM SCHEDULER
@import "../node_modules/@bryntum/schedulerpro/schedulerpro.material.css";
/* You can add global styles to this file, and also import other style files */
$theme-background-map: map-get($mat-light-theme, background);
$theme-foreground-map: map-get($mat-light-theme, foreground);
$theme-text: mat.get-color-from-palette($theme-foreground-map, text);
$theme-divider: mat.get-color-from-palette($theme-foreground-map, divider);
$theme-secondary-text: mat.get-color-from-palette(
  $theme-foreground-map,
  secondary-text
);
$theme-app-bar: mat.get-color-from-palette($theme-background-map, app-bar);
$theme-card: mat.get-color-from-palette($theme-background-map, card);
$theme-icon: mat.get-color-from-palette($theme-foreground-map, icon);
$operatingStateId1: #63a021;
$operatingStateId2: #ea670d;
$operatingStateId3: #b01f00;
$operatingStateId4: #e8e8e8;

// LEGACY
@import "styles/tables";
@import "styles/flex";
@import "styles/spaces";
@import "styles/messages";
@import "styles/material";

// APP LOCAL COMPONENT STYLES IMPORT
@import "./app/app.theme";

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($mat-light-theme);
$wh-typography: mat.define-typography-config();
@include mat.typography-hierarchy($wh-typography);
@include mat.core();

// FONTS
@include w-h-font-mixin($mat-light-theme, "/assets/fonts");

// CUSTOM THEME
@include workheld-angular-material-theme($mat-light-theme);

// LOCAL SCOPE
@include app-theme-mixin($mat-light-theme);

.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-label-text-color: #fff !important;
  --mdc-protected-button-label-text-color: #fff !important;
}

.mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-label-text-color: #fff !important;
  --mdc-protected-button-label-text-color: #fff !important;
}
.mdc-button__label {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.mat-mdc-fab,
.mat-mdc-mini-fab {
  box-shadow: none !important;
  color: var(--accent-color-50) !important;
}

mat-form-field {
  @include mat.all-component-densities(-3);
}

.wh-kiosk-view {
  lib-w-h-layout-toolbar {
    display: none;
  }

  .breadcrumbs-container {
    display: none !important;
  }

  .w-h-page-breadcrumbs {
    display: none;
  }

  .w-h-page-content,
  .toolbar-container {
    height: 100vh !important;
    overflow: hidden;
  }

  .daily-dashboard-main-container {
    height: calc(100vh - 132px) !important;
  }
}

// ASSIGNMENT BOX
.wh-assignment-box {
  background-color: #f4f4f4;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  min-width: 0px;

  .mat-mdc-tab-body-content {
    box-sizing: border-box;
  }
}

// brytnum scheduler styles
.b-schedulertaskeditor {
  .b-anchor {
    path {
      fill: var(--primary-color-500);
    }
  }
}

.b-popup-header {
  background-color: var(--primary-color-500) !important;

  .b-popup-close {
    color: var(--primary-color-50) !important;
  }
}

.b-mask-content {
  background-color: var(--primary-color-500) !important;
}

.workheld-mobile-nav__icon-button {
  mat-icon {
    margin: 0 !important;
  }
  color: white !important;
}

.workheld-mobile-nav__button {
  .mat-mdc-menu-item-text {
    display: flex !important;
    align-items: center !important;
    i {
      margin-right: 16px !important;
    }
  }
}

.scroll-background {
  background:
    linear-gradient(#f4f4f4 30%, rgb(114 114 114 / 0%)),
    linear-gradient(rgba(255, 255, 255, 0), #f4f4f4 70%) 0 100%,
    radial-gradient(farthest-side at 50% 0, rgb(0 0 0 / 20%), rgba(0, 0, 0, 0)),
    radial-gradient(
        farthest-side at 50% 100%,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      )
      0 100%;
  background-repeat: no-repeat;
  background-size:
    100% 40px,
    100% 40px,
    100% 14px,
    100% 14px;
  background-attachment: local, local, scroll, scroll;
}
