/* -- Material Design Table style -------------- */

// Variables
// ---------------------
$table-header-font-weight: 400;
$table-header-font-color: #757575;

$table-header-padding: 20px;
$table-condensed-header-padding: calc($table-header-padding/2);

$table-cell-padding: 16px;
$table-condensed-cell-padding: calc($table-cell-padding/2);

$table-bg: #fff;
$table-bg-accent: #f5f5f5;
$table-bg-hover: #f5f5f5;
$table-bg-active: $table-bg-hover;
$table-border-color: #e0e0e0;
$table-grey-header-color: #fafafa;

// Tables
//
// -----------------

// Condensed table w/ half padding
.table-condensed {
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th {
        padding: $table-condensed-header-padding;
      }
      > td {
        padding: $table-condensed-cell-padding;
      }
    }
  }
}

// Bordered version
//
// Add horizontal borders between columns.
.table-bordered {
  border: 0;
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        border: 0;
        // border-bottom: 1px solid $table-border-color;
      }
    }
  }
  > thead > tr {
    > th,
    > td {
      border-bottom-width: 2px;
    }
  }
}

// Zebra-striping
//
// Default zebra-stripe styles (alternating gray and transparent backgrounds)
.table-striped {
  > tbody > tr:nth-child(even) {
    > td,
    > th {
      background-color: $table-bg-accent;
    }
  }
}

// Hover effect
//
.table-hover {
  > tbody > tr:hover {
    > td,
    > th {
      background-color: $table-bg-hover;
    }
  }
}

// CSS/LESS Color variations
//
// --------------------------------

.table-striped.table-mc-red > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-red > tbody > tr:nth-child(odd) > th {
  background-color: #fde0dc;
}
.table-hover.table-mc-red > tbody > tr:hover > td,
.table-hover.table-mc-red > tbody > tr:hover > th {
  background-color: #f9bdbb;
}
@media screen and (max-width: 767px) {
  .table-responsive-vertical .table-striped.table-mc-red > tbody > tr > td,
  .table-responsive-vertical
    .table-striped.table-mc-red
    > tbody
    > tr:nth-child(odd) {
    background-color: $table-bg;
  }
  .table-responsive-vertical
    .table-striped.table-mc-red
    > tbody
    > tr
    > td:nth-child(odd) {
    background-color: #fde0dc;
  }
  .table-responsive-vertical .table-hover.table-mc-red > tbody > tr:hover > td,
  .table-responsive-vertical .table-hover.table-mc-red > tbody > tr:hover {
    background-color: $table-bg;
  }
  .table-responsive-vertical .table-hover.table-mc-red > tbody > tr > td:hover {
    background-color: #f9bdbb;
  }
}

.table-grey-header {
  thead {
    background-color: #eeeeee;
  }
}

// CUSTOM
// DATA TABLE

.mat-mdc-header-cell.w-h-cell-128 {
  max-width: 128px;
}
.mat-mdc-cell.w-h-cell-128 {
  max-width: 128px;
}
.mat-mdc-header-cell.w-h-cell-170 {
  max-width: 170px;
}
.mat-mdc-cell.w-h-cell-170 {
  max-width: 170px;
}
.w-h-cell-170 {
  max-width: 170px;
}

.mat-mdc-header-cell {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0px 4px !important;
}
.mat-mdc-cell {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0px 4px !important;

  i {
    font-size: 24px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.data-table-container {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  min-width: 300px;
  position: relative;
}

.paginator.mgn-top-auto {
  margin-top: auto;
}

.data-table-loading-shade {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 52px;
  right: 0;
  background: rgba(0, 0, 0, 0.15);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.data-table-loading-shade.data-table-no-data-overlay {
  background: rgba(255, 255, 255, 0.15);
}

.actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  .create {
    margin-left: $padding__page;
  }
}

.clickable {
  cursor: pointer;
}

.data-table {
  // overflow: auto;
  width: 100%;
  min-height: calc(100vh - 264px);
  cursor: pointer;
  .mat-mdc-cell.image-cell,
  .mat-mdc-header-cell.image-cell {
    flex: 0;
    min-width: 62px;
    justify-content: center;
    img {
      border-radius: 0;
      height: 35px;
      width: 35px;
      margin-right: $padding__page;
      vertical-align: middle;
    }
  }
  .mat-mdc-cell.nav-cell,
  .mat-mdc-header-cell.nav-cell {
    flex: 0;
    justify-content: flex-end;
    min-width: 80px;

    .mdc-icon-button {
      border-radius: 50%;
      height: 46px;
      width: 46px;
      vertical-align: middle;
    }
  }
  .mat-mdc-header-row {
    background: $theme-app-bar;
    min-height: 40px;
  }

  .mat-mdc-cell.actions-cell,
  .mat-mdc-header-cell.actions-cell {
    flex: 1;
    justify-content: flex-end;
    min-width: 40px;
    overflow: visible;
  }
}

.data-table-human-resources {
  // overflow: auto;
  width: 100%;
  cursor: pointer;
  .mat-mdc-cell.image-cell,
  .mat-mdc-header-cell.image-cell {
    flex: 0;
    min-width: 62px;
    justify-content: center;
    img {
      border-radius: 0;
      height: 35px;
      width: 35px;
      margin-right: $padding__page;
      vertical-align: middle;
    }
  }
  .mat-mdc-cell.nav-cell,
  .mat-mdc-header-cell.nav-cell {
    flex: 0;
    justify-content: flex-end;
    min-width: 80px;

    .mdc-icon-button {
      border-radius: 50%;
      height: 46px;
      width: 46px;
      vertical-align: middle;
    }
  }
  .mat-mdc-header-row {
    background: $theme-app-bar;
    min-height: 40px;
  }

  .mat-mdc-cell.actions-cell,
  .mat-mdc-header-cell.actions-cell {
    flex: 1;
    justify-content: flex-end;
    min-width: 40px;
    overflow: visible;
  }
}

.mat-mdc-row {
  &:hover {
    background-color: $table-bg-hover !important;
  }
}
