// APP

@mixin entry-static-data-mixin($theme) {
  .w-h-entry-static-data {
    overflow: auto;
    overflow-x: hidden;
    margin: 0px -15px;
    padding: 16px 16px 0px 16px;
    max-height: 45vh;
  }

  .entry-detail-row-wrapper {
    display: grid;
    width: 100%;
    gap: 16px;
    grid-template-columns: 24% 24% 24% 24%;
  }
}
