// APP

@mixin entry-file-data-table-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);
  .entry-file-data-table {
    ng-bee-entry-static-data-simple {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    ng-bee-work-order-static-data {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    ng-bee-work-step-static-data {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    ng-bee-worker-static-data {
      width: 99%;
    }

    .mat-mdc-row.corrupted {
      .mat-mdc-cell {
        color: #aaa;
      }
    }

    .entry-file-data-table-container {
      .mat-stroked-button:not([disabled]).create.no-filter {
        border-color: mat.get-color-from-palette($accent, 500);
        background-color: mat.get-color-from-palette($accent, 500);
        color: mat.get-color-from-palette($accent, default-contrast);
      }

      .mat-stroked-button:not([disabled]).filter-active {
        border-color: mat.get-color-from-palette($accent, 500);
        background-color: mat.get-color-from-palette($accent, 500);
        color: mat.get-color-from-palette($accent, default-contrast);
      }

      .data-table-toolbar {
        height: 64px;
        .left-side-wrapper {
          height: 100%;
        }
        .right-side-wrapper {
          text-align: end;
          align-self: center;
        }
      }
      .data-table-container {
        min-height: 227px;
      }

      .mat-mdc-table {
        width: 100%;
        overflow: auto;
        min-height: 170px;
      }

      table {
        width: 100%;
      }

      // DATE CELLS
      .mat-mdc-header-cell.date-header {
        max-width: 128px;
      }

      .mat-mdc-cell.date-cell {
        max-width: 128px;
      }

      // ACTION CELLS
      .mat-mdc-header-cell.entry-file-action-header {
        max-width: 128px;
      }

      .mat-mdc-cell.entry-file-action-cell {
        max-width: 128px;
        justify-content: flex-end;
      }

      .mat-mdc-row:hover {
        .mdc-icon-button.entry-file-action-button {
          background-color: mat.get-color-from-palette($primary, 400);
          color: mat.get-color-from-palette($accent);
        }
        .entry-file-icon {
          color: mat.get-color-from-palette($accent);
        }
      }
    }
  }
}
