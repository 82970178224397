// APP

@mixin case-create-edit-form-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);

  $prefix: "equipment-dropdown";

  .add-solution-button {
    margin-bottom: $padding__page;
  }

  .w-h-case-crud-edit-form {
    padding: $padding__page;
    max-height: calc(100vh - 144px);
    min-height: calc(100vh - 144px);
    overflow: auto;
  }

  ng-bee-customer-autocomplete.case-crud .customer-drop-down {
    .dropdown {
      top: 104px;
    }
    .customer-input {
      margin-top: unset;
    }
  }
  ng-bee-customer-autocomplete.case-crud.equipment .customer-drop-down {
    .dropdown {
      top: 166px;
    }
    .customer-input {
      margin-top: unset;
    }
  }

  .eq-select-span {
    max-width: calc(100% - 70px);
  }

  .case-create-edit-form {
    padding: $padding__page;

    .mat-mdc-form-field.data {
      width: 90%;
    }

    .occurence-date {
      width: 90%;
    }

    .mat-mdc-form-field.form-field-full-width {
      width: 100%;
    }

    .workheld-icons.w-h-expand-icon {
      font-size: 24px;
    }
  }
  .case-crud-button {
    margin-left: $padding__page;
  }

  .equipment-drop-down {
    max-width: 100%;
  }

  .equipment-drop-down:hover {
    background-color: #eee;
  }

  .workheld-icons.equipment-icon {
    color: mat.get-color-from-palette($accent);
    font-size: 44px;
    margin-right: $padding__page;
    align-self: center;
  }

  .equipment-img {
    width: 54px;
    height: 54px;
    max-width: 54px;
    max-height: 54px;
    margin-right: $padding__page;
  }

  .select-eq-span {
    width: calc(100% - (54px));
    display: inline-block;
    min-height: 54px;
    max-height: 54px;
    cursor: pointer;
  }

  .equipment-wrapper {
    align-self: center;
    cursor: pointer;

    .workheld-icons.equipment-placeholder-icon {
      color: mat.get-color-from-palette($accent);
      vertical-align: bottom;
    }

    .select-equipment-message {
      padding: 0 $padding__page;
    }

    .equipment-detail-span {
      padding: 0 $padding__page;
    }

    .workheld-icons.display-equipment-icon {
      color: mat.get-color-from-palette($primary);
      vertical-align: bottom;
      font-size: 20px;
      height: 20px;
      width: 24px;
    }
  }

  .mdc-icon-button.equipment-dropdown-btn {
    height: 54px;
    border-radius: 0px;
  }

  .equipment-loading-shade {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0px;
    right: 0;
    background: rgba(0, 0, 0, 0.15);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .#{$prefix} {
    &-container {
      position: relative;
      display: flex;
      align-items: center;
    }

    &-btn {
      display: flex;
      justify-content: center;
      color: mat.get-color-from-palette($accent);
      font-size: 28px;
      height: 54px;
      margin-left: auto;
      border-radius: 0px;

      .material-icons.expand_more {
        font-size: 34px;
        line-height: 34px;
        height: 34px;
        width: 34px;
        color: mat.get-color-from-palette($accent);
      }
    }
  }
  .dropdown.eq-dropdown-new {
    background: white;
    position: absolute;
    max-width: 31%;
    min-width: 31%;
    z-index: 1000;
    transform: translateY(0) scale(0);
    transform-origin: top;
    visibility: hidden;
    transition:
      transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
      visibility 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);

    @media screen and (max-width: 599px) {
      min-width: 50vw;
      right: 5px;
      transform: translateY(0);
      visibility: hidden;
      transition:
        transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
        visibility 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    }

    &.open {
      transform: translateY(0) scale(1);
      visibility: visible;
      max-width: 31%;
    }
    .card {
      .header {
        background: #eeeeee;
        min-height: 54px;
        padding-left: 4px;
        padding-right: 8px;
        padding-top: 8px;
        color: #555;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        align-content: center;
        border-bottom: 1px solid #e0e0e0;

        .search-term {
          width: 100%;
          margin-top: 6px;
        }

        .extra {
          font-size: 12px;
          color: #888;
        }
      }
    }
    .content {
      overflow: auto;
      max-height: 55vh;

      .equipment {
        min-height: 54px;
        padding: 0 16px 0 14px;
        position: relative;
        cursor: pointer;

        .avatar-wrapper {
          text-align: center;
        }

        .icon {
          height: 28px;
          width: 28px;
          line-height: 28px;
          font-size: 18px;
          text-align: center;
          border-radius: 50%;
          background: #fff;
          color: #888;
          border: 1px solid #eee;
          margin: auto 13px auto auto;
        }

        .status {
          text-align: center;
          font-size: 8px;
        }

        .title {
          margin: 0px 0px 0px 15px;
          font-weight: 500;
          font-size: 14px;
          width: 100%;
        }

        .number-ext {
          font-size: 12px;

          .eq-num {
            min-width: 50%;
          }
          .eq-customer {
            text-overflow: ellipsis;
            overflow: hidden;
          }
          ng-bee-customer-static-data {
            overflow: hidden;
          }
        }

        .close {
          font-size: 18px;
          width: 18px;
          height: 18px;
          line-height: 18px;
        }

        &.primary {
          .icon {
            background: #ccc;
            color: #ddd;
          }
        }

        &.accent {
          .icon {
            background: #aaa;
            color: #bbb;
          }
        }

        &.warn {
          .icon {
            background: #eee;
            color: #ddd;
          }
        }

        &.read {
          color: #999;

          .name {
            font-weight: normal;
          }
        }
      }
    }
    .equipment.selected {
      background-color: mat.get-color-from-palette($primary, 200);
      color: #fff;
    }

    .footer {
      min-height: 42px;
      border-top: 1px solid #eee;

      .action {
        cursor: pointer;
        color: #aaa;
        text-align: center;
        font-size: 13px;
      }
    }

    .divider {
      width: calc(100% - 30px);
      height: 1px;
      background: #eee;
      margin: 0 16px 0 14px;
    }

    .no-data-message {
      padding: $padding__page;
    }
  }

  .solution-step-body {
    padding: $padding__page;
    border-top: dashed 4px mat.get-color-from-palette($primary, 100);
  }
}
