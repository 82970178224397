// APP

@mixin project-detail-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);

  .show-bigscreen {
    display: none !important;
  }

  .w-h-project-span {
    max-height: calc(100vh - 112px);
    overflow: auto;
  }

  .project-detail-card {
    padding: 0px;
    border-radius: 0px;
    margin: $padding__page;

    ng-bee-worker-static-data.work-object-resp-tech {
      .worker-chip {
        max-width: 96%;
      }
    }

    // TOP HEADER
    .new_detail-card-header {
      padding: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .header-title-holder {
        display: flex;
        align-items: center;

        .mat-mdc-card-avatar {
          background-color: var(--primary-color-500);
          color: #fff;
          padding: 5px;
          display: grid;
          align-items: center;
          text-align: center;
        }

        h2 {
          margin: 0 0 0 10px;
        }
      }

      .new_detail-actions-wrapper {
        button {
          margin-left: 10px;
          min-height: 38px;
          mat-icon {
            margin: 0 !important;
          }
        }

        button.create {
          background-color: #25882a;
          color: #ffffff;
        }
      }
    }

    .work-order-summary-label-row {
      margin-top: 8px;
    }

    .work-object-summary-wrapper {
      overflow-x: hidden;
      border: 1px solid transparent;
      height: 100%;

      > span {
        height: 100%;
      }
    }

    // BODY DATA
    .project-detail-card-body {
      padding: $padding__page;
      display: flex;
    }
  }

  .mat-menu-content {
    span {
      width: inherit;
    }
  }
}

@media screen and (min-width: 2160px) {
  .show-bigscreen {
    display: inline-block !important;
  }
}

::ng-deep {
  .mat-mdc-tab-body-content {
    overflow: hidden !important;
  }
  .mat-mdc-menu-item-text {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
