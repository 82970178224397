// APP

@mixin work-object-static-data-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);

  .contact-person-detail {
    padding-top: 10px;
    padding-right: 20px;

    .contact-box {

      .contact-field {
        line-height: 1;
        border: 1px solid #e3e3e3;
        border-radius: 8px;
        cursor: pointer;
        padding: 4px 0;
        min-height: 86px;
      }

      .workheld-icons.contact-icon {
        color: mat.get-color-from-palette($accent);
        font-size: 24px;
      }

      .add-contact-line,
      .add-contact-box {
        height: 64px;
        border: 1px solid #e3e3e3;
        line-height: 64px;
        border-radius: 10px;
      }

      .contact-icon {
        color: mat.get-color-from-palette($accent);
        font-size: 30px;
      }
    }
  }

  .workheld-icons.w-h-info-icon {
    display: inline-flex;
    vertical-align: sub;
    margin-left: $padding__page;
  }
}