// APP

@mixin checklist-definition-create-edit-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);

  .checklist-definition-create-edit-card {
    padding: 0px;
    border-radius: 0px;
    margin: $padding__page;

    .section-element {
      margin-top: $padding__page;
      padding: $padding__page;
    }

    .workheld-icons.delete-section {
      color: mat.get-color-from-palette($accent);
      cursor: pointer;
      width: 24px;
      height: 24px;
      margin-left: $padding__page;
      vertical-align: bottom;
    }

    .section-header-toggle {
      display: flex;
      align-self: center;
    }

    .section-header-close {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    .checklist-definition-create-edit-card-body {
      padding: $padding__page;
      min-height: calc(100vh - 276px);
    }

    .add-check-button {
      border-radius: 0px;
    }

    .add-section-button {
      margin-top: $padding__page;
      border-radius: 0px;
    }

    .check-definition-dnd-list {
      max-width: 100%;
      display: block;
      background: white;
      border-radius: 0px;
      overflow: hidden;
    }

    .check-definition-dnd-box {
      color: rgba(0, 0, 0, 0.87);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      background: white;
    }

    .check-definition-action-span {
      min-height: 65.5px;
      text-align: center;
      align-items: flex-start;
      margin-top: 28px;
    }

    .check-definition-dnd-handle {
      color: mat.get-color-from-palette($accent);
      cursor: move;
      width: 24px;
      height: 24px;
    }

    .check-definition-delete {
      color: mat.get-color-from-palette($accent);
      cursor: pointer;
      width: 24px;
      height: 24px;
      margin-left: $padding__page;
    }

    .cdk-drag-preview {
      box-sizing: border-box;
      border-radius: 0px;
      box-shadow:
        0 5px 5px -3px rgba(0, 0, 0, 0.2),
        0 8px 10px 1px rgba(0, 0, 0, 0.14),
        0 3px 14px 2px rgba(0, 0, 0, 0.12);
    }

    .cdk-drag-animating {
      transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
    }

    .check-definition-dnd-box:last-child {
      border: none;
    }

    .check-definition-dnd-list.cdk-drop-list-dragging
      .check-definition-dnd-box:not(.cdk-drag-placeholder) {
      transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
    }

    .check-definition-dnd-placeholder {
      opacity: 1;
      background: #ccc;
      border: dotted 2px mat.get-color-from-palette($accent);
      min-height: 110px;
      transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
      cursor: grabbing;
    }
  }

  .check-definition-dnd-box.cdk-drag-preview {
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    background: white;

    .check-definition-action-span {
      min-height: 65.5px;
      padding-top: $padding__page;
      text-align: center;
    }

    .check-definition-dnd-handle {
      color: mat.get-color-from-palette($accent);
      cursor: move;
      width: 24px;
      height: 24px;
    }

    .check-definition-delete {
      color: mat.get-color-from-palette($accent);
      cursor: pointer;
      width: 24px;
      height: 24px;
      margin-left: $padding__page;
    }
  }
}
