// APP

@mixin mat-dialog-confirm-entry-component-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);

  .confirm-mat-dialog {
    img {
      width: 25%;
    }

    .label-wrapper {
      margin-bottom: 16px;
    }

    .confirm-title {
      margin: 8px 0 0 0;
      padding-left: 20px;
      font-weight: bold;
    }

    .confirm-label {
      align-self: center;
    }

    .confirm-text {
      padding: 16px 24px;
    }
    .mat-dialog-actions.dialog-action-wrapper {
      margin: 16px 0px 0px 0px;
      padding: 0px;
      min-height: unset;
    }
    .w-h-default-button.dialog-button {
      max-width: 45%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .mat-accent.dialog-button {
      max-width: 45%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
