// APP

@mixin w-h-date-static-data-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);

  .date-field {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    .date-label {
      display: inline-block;
      min-width: 42.5%;
      /* max-width: 40%; */
      color: #000;
    }
  }
  .date-field.date-conflict {
    color: red;

    .date-label {
      color: #000;
    }
  }
}
