// APP

@mixin app-scope-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);

  $dialog_container_border_space: 16px;

  .no-access-page {
    border-radius: 0px;
    margin: $padding__page;
    height: calc(100vh - 128px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    h2.main-message {
      font-weight: 600;
    }
    h3.extra-message {
      font-weight: 600;
    }
    h2.extra-message {
      font-weight: 500;
    }
  }

  .mat-button.mat-flat-button {
    overflow: hidden;
  }
  ng-bee-work-object-start-stop {
    display: contents;
  }

  .workheld-icons.crumb-icon {
    display: inline-block;
  }

  .cdk-overlay-container .mat-mdc-dialog-container .mat-mdc-dialog-surface {
    border-radius: 0px;
    overflow: hidden;
    .w-h-ngx-image-cropper-mat-dialog {
      overflow: hidden;
    }
  }

  .mat-divider.wh-dialog-divider {
    margin-left: -$dialog_container_border_space;
    margin-right: -$dialog_container_border_space;
  }

  // SIDENAV CONTAINER
  .mat-sidenav.sidenav-container {
    @include mat.elevation(1);
    width: $width__sidenav;
    transition: width 0.1s;
  }

  mat-sidenav-content.mat-drawer-content {
    position: relative;
    z-index: 1;
    display: block;
    height: 100%;
    overflow: hidden;
  }

  .w-h-call-module {
    padding: 0;
  }

  .layout.delta {
    .w-h-page-breadcrumbs {
      display: flex;
      align-items: center;
      min-height: 46px;
    }

    .w-h-page-content {
      height: calc(100vh - 72px);
    }
  }

  .toolbar-container {
    height: calc(100vh - 64px);
    overflow: hidden;
  }

  .w-h-page-content {
    height: calc(100vh - 112px);
    overflow: auto;
  }

  .w-h-page-breadcrumbs {
    @include mat.elevation(1);
    background-color: #fff;
    padding-left: 16px;
    display: flex;
    align-items: center;
    min-height: 38px;
    box-shadow: unset;

    .w-h-crumb {
      display: inline-grid;
      cursor: pointer;
      .workheld-icons {
        font-size: 20px;
      }
    }
    .w-h-crumb:last-child {
      cursor: default;
    }
  }

  .page-card {
    @include mat.elevation(2);
  }

  .page-card-content-wrapper {
    min-height: calc(100vh - 216px);
  }

  .page-card-dropdown-wrapper {
    margin-left: 16px;
    padding-top: 10px;
  }

  .mat-mdc-table.w-h-data-table {
    width: 100%;
    min-height: calc(100vh - 268px);
  }

  // CHECKLIST DEFINITIONS
  .page-card.w-h-checklist-definition-crud-form .check-definition-dnd-list {
    max-height: calc(100vh - 508px);
    overflow-x: hidden;
    overflow-y: auto;
  }

  // PROFILE PAGE
  .page-card.w-h-profile-page {
    .page-card-content-wrapper {
      min-height: calc(100vh - 260px);
    }

    .mat-raised-button.r-card-action-button {
      color: mat.get-color-from-palette($accent);
    }
  }

  // DIAGNOSTICS PAGE
  .page-card.w-h-diagnostic-page {
    min-height: calc(100vh - 148px);
    .w-h-i18n-page {
      padding: 0px;
      .mat-card-content.page-card-content {
        padding: 0px;
        margin-bottom: 0px;
      }
    }
    .page-card {
      padding: 0px;
      box-shadow: unset;
      min-height: calc(100vh - 228px);
    }
    .page-card-content-wrapper {
      padding: 0px;
      min-height: calc(100vh - 228px);
      .mat-card-content.page-card-content {
        margin-bottom: 0px;
      }
    }

    .page-card.w-h-permission-page {
      margin: 0px;
      .page-card-content-wrapper {
        .mat-card-content.page-card-content {
          padding: 0px;
          margin-bottom: 0px;
          .mat-mdc-table.w-h-data-table {
            width: 100%;
            min-height: calc(100vh - 402px);
          }
        }
      }
    }

    .page-card.w-h-theme-page {
      margin: 0px;
      .page-card-content-wrapper {
        .mat-card-content.page-card-content {
          padding: 0px;
        }
      }
    }

    .page-card.w-h-feature-flag-page {
      margin: 0px;
      min-height: calc(100vh - 228px);
      box-shadow: unset;
      margin-bottom: 0px;
      .page-card-content-wrapper {
        min-height: calc(100vh - 228px);
        .mat-card-content.page-card-content {
          padding: 0px;
          margin-bottom: 0px;
          .mat-mdc-table.w-h-data-table {
            width: 100%;
            min-height: calc(100vh - 402px);
          }
        }
      }
    }
  }

  a:link {
    color: #0f75e8;
    text-decoration: none;
    border-radius: 4px;
    padding: 0px 4px;
    transition: 0.2s;
    &:hover {
      color: white;
      text-decoration: underline;
      background-color: #0f75e8;
    }
  }
}

.wh-blur-dialog-backdrop {
  backdrop-filter: blur(3px);
}

.right-side-dialog {
  max-width: 500px !important;
  width: 500px;
  ::ng-deep {
    .mat-mdc-dialog-container {
      border-radius: 8px !important;
    }
  }
  position: absolute !important;
  top: 0;
  right: 0;
  height: 96vh;
  margin: 16px 16px 0px 0px;
  min-width: 500px;
}

.cdk-overlay-backdrop {
  background: rgba(0, 0, 0, 0.3);
}

.mat-mdc-card-avatar {
  margin-bottom: 0px !important;
}

//help menu version
:host::ng-deep {
  .helpmenu-item-container .helpmenu-dropdown a.dropdown-item:last-child {
    pointer-events: none;
    a {
      color: gray;
    }
  }
}

mat-form-field:not(.search-field) {
  margin: 6px 0px;
}

.material-preview-dialog {
  max-width: 95vw !important;
}

.entry-count {
  background: #e9e9e9;
  padding: 4px;
  border-radius: 16px;
  height: 20px;
  min-width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #cccccc;
}
