// APP

@mixin w-h-status-bar-util-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);

  .w-h-status-bar-wrapper {
    display: flex;
    padding: 4px 10px;
    white-space: nowrap;
    gap: 23px;
    z-index: 20;
    background-color: #fff;
    position: sticky;
    top: 0;
    justify-content: center;

    > div {
      width: 100%;
      background-color: #ebebeb;
      padding: 12px 14px;
      border-radius: 12px 12px 0px 0px;
      font-weight: 600;
      color: mat.get-color-from-palette($primary);
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    ol.w-h-status-bar {
      $line_height: 1.2em;
      $padding: 9px;
      $arrow_size: 18px;
      $max_li: 4;

      list-style: none;
      text-align: center;
      margin: 0;
      padding: 0;

      li {
        list-style-image: none;
        list-style-type: none;
        display: inline-block;
        margin: 0 20px;
        position: relative;

        &:before {
          content: "";
          border: 1px solid mat.get-color-from-palette($primary, 400);
          width: 10px;
          height: 10px;
          display: block;
          border-radius: 100%;
          margin: 0 auto 10px;
        }

        &:after {
          content: "";
          width: 20px;
          height: 2px;
          background: mat.get-color-from-palette($primary, 400);
          display: block;
          position: absolute;
          bottom: 10px;
          right: -30px;
        }

        // No line on last item.
        &:last-child {
          &:after {
            display: none;
          }
        }
      }

      li.current {
        &:before {
          background-color: mat.get-color-from-palette($primary, 400);
        }
      }
    }
  }
}
