// APP

@mixin report-file-data-table-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);
  .report-file-data-table {
    .report-file-data-table-container {
      // margin-bottom: $padding__page;
      .mat-stroked-button:not([disabled]).create.no-filter {
        border-color: mat.get-color-from-palette($accent, 500);
        background-color: mat.get-color-from-palette($accent, 500);
        color: mat.get-color-from-palette($accent, default-contrast);
      }

      .mat-stroked-button:not([disabled]).filter-active {
        border-color: mat.get-color-from-palette($accent, 500);
        background-color: mat.get-color-from-palette($accent, 500);
        color: mat.get-color-from-palette($accent, default-contrast);
      }

      ng-bee-worker-static-data {
        width: 99%;
      }

      .data-table-toolbar {
        height: 64px;
        padding-right: $padding__page;
        .left-side-wrapper {
          height: 100%;
        }
        .right-side-wrapper {
          text-align: end;
          align-self: center;
        }
      }

      .mat-mdc-row.corrupted {
        .mat-mdc-cell {
          color: #aaa;
        }
      }

      .data-table-container {
        min-height: 227px;
      }

      .mat-mdc-table {
        width: 100%;
        overflow: auto;
        min-height: 170px;
      }

      table {
        width: 100%;
      }

      // DATE CELLS
      .mat-mdc-header-cell.date-header {
        max-width: 128px;
      }

      .mat-mdc-cell.date-cell {
        max-width: 128px;
      }

      // ACTION CELLS
      .mat-mdc-header-cell.report-action-header {
        max-width: 128px;
      }

      .mat-mdc-cell.report-action-cell {
        max-width: 128px;
        justify-content: flex-end;
      }

      .mat-mdc-row:hover {
        .report-file-action-button {
          color: #fff;
          background-color: mat.get-color-from-palette($accent);
        }
        .report-file-icon {
          color: mat.get-color-from-palette($accent);
        }
      }
    }
  }
}
